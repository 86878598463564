import { useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';
import * as completeTickAnimation from './animations/complete-tick.json';

const Lottie = dynamic(() => import('react-lottie'), { ssr: false });

type SetupCompleteProps = {
  onWelcomeAnimationCompleted: () => void;
};

export default function SetupComplete({ onWelcomeAnimationCompleted }: SetupCompleteProps) {
  const { t } = useTranslation('users');
  return (
    <div>
      <Lottie
        options={{
          loop: false,
          autoplay: true,
          animationData: completeTickAnimation,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
          }
        }}
        height={400}
        width={400}
        eventListeners={[
          {
            eventName: 'complete',
            callback: onWelcomeAnimationCompleted
          }
        ]}
      />
      <p className="fs-3 fw-bold">{t('accountSetup.complete')}</p>
    </div>
  );
}
