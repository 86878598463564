import { useTranslation } from 'next-i18next';
import SelectField from './SelectField';

type LanguageSelectFieldProps = {
  name: string;
  label: string;
  required?: boolean;
  readOnly?: boolean;
};

export default function LanguageSelectField({
  name,
  label,
  required = false,
  readOnly = false
}: LanguageSelectFieldProps) {
  const { t } = useTranslation('common');

  const languageOptions = [
    { value: 'DA', label: t('languages.DA') },
    { value: 'EN', label: t('languages.EN') }
  ];

  return (
    <SelectField
      name={name}
      label={label}
      options={languageOptions}
      required={required}
      readOnly={readOnly}
    />
  );
}
