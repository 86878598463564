import { useCallback } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  DeepPartial,
  DefaultValues,
  FieldValues,
  UnpackNestedValue,
  useForm as useReactHookForm
} from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import { AnyObjectSchema, setLocale } from 'yup';
import defaultLocale from 'yup/lib/locale';
import yupLocaleDA from '../locales/yupLocaleDA';

type FormProps<TFormData extends FieldValues> = {
  defaultValues?: UnpackNestedValue<DeepPartial<TFormData>>;
  validationSchema: AnyObjectSchema;
  showSuccessNotification?: boolean;
};

function useForm<TFormData extends FieldValues>({
  defaultValues = undefined,
  validationSchema
}: FormProps<TFormData>) {
  const { i18n } = useTranslation();

  const form = useReactHookForm<TFormData>({
    // @ts-ignore
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues as DefaultValues<TFormData>
  });

  if (i18n.language === 'da') {
    setLocale(yupLocaleDA);
  } else {
    setLocale(defaultLocale);
  }

  const isRequired = useCallback(
    (field: string) => validationSchema.fields[field]?.exclusiveTests.required || false,
    [validationSchema]
  );

  return {
    handleSubmit: form.handleSubmit,
    form,
    isRequired
  };
}

export default useForm;
