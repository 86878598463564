import { FormProvider } from 'react-hook-form';

type FormWrapperProps = {
  children: React.ReactNode | Function;
  onSubmit: any;
  form: any;
  className?: string;
};

function FormWrapper({ children, form, onSubmit, className }: FormWrapperProps) {
  return (
    <div className={className}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          {typeof children === 'function' ? children(form) : children}
        </form>
      </FormProvider>
    </div>
  );
}

export default FormWrapper;
