import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import useFeatures from '~/features/shared/hooks/useFeatures';
import { PageItem } from './PageItem';

type MenuItemProps = {
  className?: string;
  anchorClassName?: string;
  page: PageItem;
};

function MenuItem({ page, className = undefined, anchorClassName = undefined }: MenuItemProps) {
  const { isEnabled } = useFeatures();

  const { pathname } = useRouter();

  let featureDisabled = false;
  if (page.featureFlag) {
    const featureFlagEnabled = isEnabled(page.featureFlag.replace('!', ''));
    featureDisabled = page.featureFlag.startsWith('!') ? featureFlagEnabled : !featureFlagEnabled;
  }

  const active = page.href === pathname;

  return (
    <li className={classNames('side-nav-item', className, { 'd-none': featureDisabled })}>
      <Link
        href={page.href}
        target={page.target ? page.target : undefined}
        className={classNames('side-nav-link', anchorClassName, page.anchorClassName, { active })}
      >
        {page.icon}
        <span>{page.title}</span>
        <span className="float-end">{page.badge}</span>
      </Link>
    </li>
  );
}

export default MenuItem;
