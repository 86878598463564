import React from 'react';
import RouterLink from 'next/link';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import Logo from '~/features/shared/components/Logo';
import Profile from './Profile';
import useUser from '~/contexts/User/useUser';
import useSidebar from '~/contexts/Sidebar/useSidebar';
import { HEADER_HEIGHT } from '~/features/shared/constants';

type TopProps = {
  toggleSidebar: () => void;
};

function Top({ toggleSidebar }: TopProps): JSX.Element {
  const { isImpersonating, clearImpersonatedUser } = useUser();
  const { t: tCommon } = useTranslation('common');
  const { isFocusMode, onFocusBack, onFocusClose } = useSidebar();

  const focusModeTopbar = (
    <div className="d-flex justify-content-between align-items-center w-100">
      <button
        data-testid="topbar-back-button"
        className="btn back-button"
        type="button"
        onClick={onFocusBack}
      >
        <i className="mdi mdi-chevron-left button-icon" />
        {tCommon('buttons.back')}
      </button>
      <RouterLink href="/">
        <Logo className="position-relative" width="100%" height="50" />
      </RouterLink>
      <button
        data-testid="topbar-close-button"
        className="btn close-button"
        type="button"
        onClick={onFocusClose}
      >
        {tCommon('buttons.close')}
        <i className="mdi mdi-close button-icon" />
      </button>
    </div>
  );

  const regularTopbar = (
    <>
      <span className="d-inline d-xl-none">
        <button type="button" className="btn btn-white toggle-menu m-1" onClick={toggleSidebar}>
          <i className="mdi mdi-menu" />
        </button>
        <RouterLink href="/" legacyBehavior>
          <span>
            <Logo height="50" />
          </span>
        </RouterLink>
      </span>
      <ul className="list-unstyled topbar-menu float-end mb-0">
        <li className="dropdown notification-list">
          <Profile />
        </li>
      </ul>
      {isImpersonating && clearImpersonatedUser && (
        <button
          type="button"
          className="btn btn-sm btn-danger float-end cancel-impersonation"
          onClick={() => {
            clearImpersonatedUser();
          }}
        >
          Stop Impersonating
        </button>
      )}
    </>
  );

  return (
    <div className={classNames('topbar navbar-custom', { 'd-flex': isFocusMode })}>
      {isFocusMode ? focusModeTopbar : regularTopbar}
      <style jsx>{`
        .topbar :global(.logo) {
          display: inline-block;
          line-height: ${HEADER_HEIGHT}px;
          width: 125px;
          margin-left: -15px;
        }
        .topbar :global(.btn.cancel-impersonation) {
          margin-top: 18px;
        }
        .topbar :global(.btn.toggle-menu:focus) {
          box-shadow: none;
        }
        .topbar :global(.btn.toggle-menu i) {
          font-size: 1.5rem;
          margin: 0;
          padding: 0;
        }
        .topbar :global(.close-button, .back-button) {
          display: flex;
          align-items: center;
        }
        .topbar :global(.close-button i) {
          margin-left: 5px;
          margin-right: 0;
        }
        .topbar :global(.button-icon) {
          line-height: inherit;
          font-size: 1.5rem;
        }
      `}</style>
    </div>
  );
}

export default Top;
