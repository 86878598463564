import { useRouter } from 'next/router';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import clientConfig from '~/clientConfig';
import useUser from '~/contexts/User/useUser';

type GoogleTagManagerProps = {};

export default function GoogleTagManager({
  children
}: React.PropsWithChildren<GoogleTagManagerProps>): JSX.Element {
  const { id, displayName, email, phoneNumber, loading, referral, role, latestSentInvoiceDate, language } =
    useUser();

  const {
    query: { gtag }
  } = useRouter();

  const disableGtag = !clientConfig.googleTagManagerId || gtag === 'false';

  useEffect(() => {
    if (!disableGtag) {
      TagManager.initialize({
        gtmId: clientConfig.googleTagManagerId
      });
    }
  }, [disableGtag]);

  useEffect(() => {
    if (!loading && !disableGtag) {
      TagManager.dataLayer({
        dataLayer: {
          userId: id,
          userName: displayName,
          userEmail: email,
          userPhone: phoneNumber,
          userIsActive: !!latestSentInvoiceDate,
          userLanguage: language,
          userReferral: referral,
          userRole: role,
          environment: clientConfig.environment
        }
      });
    }
  }, [
    loading,
    id,
    displayName,
    email,
    phoneNumber,
    referral,
    disableGtag,
    role,
    latestSentInvoiceDate,
    language
  ]);

  return children as JSX.Element;
}
