'use client';

import { useMemo } from 'react';
import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  NormalizedCacheObject,
  HttpLink,
  from
} from '@apollo/client';
import merge from 'ts-deepmerge';
import { nanoid } from 'nanoid';
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from '@apollo/client/link/retry';
import { ErrorHandler } from '@factofly/error-handling-next';
import { getCookie } from '@factofly/utilities';
import { onError } from '@apollo/client/link/error';
import { GraphQLError } from 'graphql';
import isServer from './isServer';
import { SESSION_EXPIRED_PAGE, ACCOUNT_LOCKED_PAGE } from '~/constants/appRoutes';

let apolloClient: ApolloClient<NormalizedCacheObject>;

function createHttpV2Link() {
  return new HttpLink({
    uri: 'https://graphql-api-e52d794d997e.herokuapp.com/',
    credentials: 'omit',
    headers: {
      Authorization: `Bearer ${getCookie('token', document.cookie)}`
    }
  });
}

function createHttpLink() {
  return new HttpLink({
    uri: '/api/graphql',
    credentials: 'same-origin'
  });
}

function createErrorLink() {
  return onError(({ graphQLErrors, networkError, operation }) => {
    const unauthenticated = graphQLErrors?.find((error: any) => error.code === 'UNAUTHENTICATED');
    if (unauthenticated) {
      if (!window.location.href.endsWith(ACCOUNT_LOCKED_PAGE) && unauthenticated.message.includes('locked')) {
        window.location.href = ACCOUNT_LOCKED_PAGE;
        return;
      }

      if (!window.location.href.endsWith(SESSION_EXPIRED_PAGE)) {
        window.location.href = SESSION_EXPIRED_PAGE;
        return;
      }
    }

    ErrorHandler.setContext('operation', operation);
    if (graphQLErrors) ErrorHandler.setContext('errors', { graphQLErrors });
    if (networkError) ErrorHandler.setContext('networkError', { networkError });
    ErrorHandler.configureScope((scope: any) => {
      scope.setTag('request_id', operation.getContext().headers['x-request-id']);
    });
    if (graphQLErrors)
      graphQLErrors.forEach(({ message }: GraphQLError) =>
        ErrorHandler.captureException(new Error(`[GraphQL error]: ${operation.operationName} - ${message}`))
      );

    if (networkError) {
      ErrorHandler.captureException(
        new Error(`[network error]: ${operation.operationName} - ${networkError.message}`)
      );

      const language = getCookie('NEXT_LOCALE', document.cookie) ?? navigator.language;
      if (language.toLocaleLowerCase().includes('da')) {
        // eslint-disable-next-line no-param-reassign
        networkError.message =
          'Der opstod en fejl ved kontakt til serveren. Prøv venligst igen, eller kontakt os direkte.';
      } else {
        // eslint-disable-next-line no-param-reassign
        networkError.message =
          'There was an error contacting the server. Please try again or contact us directly.';
      }
    }
  });
}

function createIsomorphLink() {
  if (isServer()) {
    // TODO: Fix this
    return from([]);
  }

  const setRequestId = setContext(() => ({
    headers: { 'x-request-id': nanoid() }
  }));

  const errorLink = createErrorLink();

  const httpLink = createHttpLink();

  const httpV2Link = createHttpV2Link();

  const retryLink = new RetryLink();

  const versionSplit = ApolloLink.split(
    (operation: any) => operation.getContext().version === 2,
    httpV2Link,
    httpLink
  );

  return from([setRequestId, errorLink, retryLink, versionSplit]);
}

function createApolloClient() {
  return new ApolloClient({
    ssrMode: isServer(),
    link: createIsomorphLink(),
    cache: new InMemoryCache(),
    connectToDevTools: true
  });
}

export function initializeApollo(initialState = null) {
  const newApolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // get hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = newApolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache);

    // Restore the cache with the merged data
    newApolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return newApolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = newApolloClient;

  return newApolloClient;
}

export function useApollo(initialState: any) {
  const store = useMemo(() => initializeApollo(initialState), [initialState]);
  return store;
}
