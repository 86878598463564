import { useTranslation } from 'next-i18next';
import { useFormContext } from 'react-hook-form';
import useUser from '~/contexts/User/useUser';
import FormSection from '~/features/forms/components/FormSection';
import JobTypeSelectField from '~/features/forms/components/JobTypeSelectField';
import LanguageSelectField from '~/features/forms/components/LanguageSelectField';
import TextField from '~/features/forms/components/TextField';

type PersonalDetailsFormProps = {
  isRequired: (name: string) => boolean;
};

export default function PersonalDetailsForm({ isRequired }: PersonalDetailsFormProps) {
  const { t } = useTranslation('users');
  const { watch } = useFormContext();
  const { latestSentInvoiceDate } = useUser();
  const hasSentInvoice = !!latestSentInvoiceDate;

  return (
    <FormSection>
      <div className="row g-1">
        <div className="col-sm">
          <TextField
            name="firstName"
            label={t('labels.firstName')}
            required={isRequired('firstName')}
            readOnly={!!watch('firstName') && hasSentInvoice}
          />
        </div>
        <div className="col-sm">
          <TextField
            name="lastName"
            label={t('labels.lastName')}
            required={isRequired('lastName')}
            readOnly={!!watch('lastName') && hasSentInvoice}
          />
        </div>
      </div>
      <div className="row g-1">
        <div className="col-sm">
          <TextField
            name="phoneNumber"
            label={t('labels.phoneNumber')}
            required={isRequired('phoneNumber')}
          />
        </div>
        <div className="col-sm">
          <JobTypeSelectField
            name="jobTypeId"
            label={t('labels.jobType')}
            required={isRequired('jobTypeId')}
          />
        </div>
      </div>
      <div className="row g-1">
        <div className="col-sm">
          <LanguageSelectField
            name="language"
            label={t('labels.language')}
            required={isRequired('language')}
          />
        </div>
        <div className="col-sm" />
      </div>
    </FormSection>
  );
}
