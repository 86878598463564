export type PopupCenterArgs = {
  url: string;
  title: string;
  w: number;
  h: number;
};

// https://stackoverflow.com/questions/4068373/center-a-popup-window-on-screen
export default function popupCenter({ url, title, w, h }: PopupCenterArgs) {
  // Fixes dual-screen position                             Most browsers      Firefox
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

  // eslint-disable-next-line no-nested-ternary
  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : // eslint-disable-next-line no-restricted-globals
      screen.width;
  // eslint-disable-next-line no-nested-ternary
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : // eslint-disable-next-line no-restricted-globals
      screen.height;

  const systemZoom = 1; // width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;
  const newWindow = window.open(
    url,
    title,
    `
      scrollbars=yes,
      width=${w / systemZoom},
      height=${h / systemZoom},
      top=${top},
      left=${left}
      `
  );

  // @ts-ignore
  if (window.focus) newWindow.focus();

  return newWindow;
}
