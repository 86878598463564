import { LOGOUT_PAGE } from '~/constants/appRoutes';
import AvailableCreditBadge from '~/features/rewards/components/AvailableCreditBadge';
import { PageItem } from './PageItem';
import FeedbackMenuItem from './FeedbackMenuItem';

export const pages: PageItem[] = [
  {
    title: 'overview',
    href: '/',
    icon: <i className="uil-signal-alt-3" />,
    matches: /^\//
  },
  {
    title: 'invoices',
    href: '/invoices',
    icon: <i className="uil-invoice" />,
    matches: /^\/invoice/
  },
  {
    title: 'contracts',
    href: '/contracts',
    icon: <i className="uil-file-edit-alt" />,
    matches: /^\/contracts/,
    featureFlag: 'feature-contracts'
  },
  {
    title: 'customers',
    href: '/customers',
    matches: /^\/customer.*/,
    icon: <i className="uil-building" />
  },
  {
    title: 'tasks',
    href: '/tasks',
    matches: /^\/task.*/,
    icon: <i className="uil-calender" />
  },
  {
    title: 'time',
    href: '/time-registration',
    matches: /^\/time-registration.*/,
    icon: <i className="uil-clock" />
  },
  {
    title: 'deductions',
    href: '/deductions',
    matches: /^\/deduction.*/,
    icon: <i className="uil-bill" />
  },
  {
    title: 'mileages',
    href: '/mileages',
    matches: /^\/mileages.*/,
    icon: <i className="uil-location-point" />,
    featureFlag: 'feature-mileages'
  },
  {
    title: 'offer',
    href: '/offers',
    icon: <i className="mdi mdi-offer" />,
    matches: /^\/offers.*/,
    featureFlag: 'feature-offer-sending'
  },
  {
    title: 'payroll',
    href: '/payroll',
    icon: <i className="uil-money-stack" />,
    matches: /^\/payroll.*/,
    featureFlag: 'feature-user-payroll'
  },
  {
    title: 'payroll',
    href: '/salaries',
    icon: <i className="uil-money-stack" />,
    matches: /^\/payroll.*/,
    featureFlag: '!feature-user-payroll'
  }
];

export const admin: PageItem[] = [
  {
    title: 'overview',
    href: '/admin',
    icon: <i className="uil-signal-alt-3" />,
    matches: /^\/admin/
  },
  {
    title: 'reports',
    href: '/admin/reports',
    matches: /^\/admin\/reports.*/,
    icon: <i className="uil-chart" />
  },
  {
    title: 'users',
    href: '/admin/users',
    matches: /^\/admin\/user.*/,
    icon: <i className="uil-user" />
  },
  {
    title: 'referral',
    href: '/admin/referrals',
    matches: /^\/admin\/referrals.*/,
    icon: <i className="uil-users-alt" />
  },
  {
    title: 'contracts',
    href: '/admin/contracts',
    icon: <i className="uil-file-edit-alt" />,
    matches: /^\/admin\/contracts/
  },
  {
    title: 'contractTemplates',
    href: '/admin/contract-templates',
    icon: <i className="uil-copy" />,
    matches: /^\/admin\/contract-templates/
  },
  {
    title: 'invoices',
    href: '/admin/invoices',
    icon: <i className="uil-invoice" />,
    matches: /^\/admin\/invoice.*/
  },
  {
    title: 'payroll',
    href: '/admin/salaries/upcoming',
    icon: <i className="uil-money-stack" />,
    matches: /^\/admin\/salaries.*/
  },
  {
    title: 'companies',
    href: '/admin/companies',
    matches: /^\/admin\/companies.*/,
    icon: <i className="uil-folder-check" />
  },
  {
    title: 'customers',
    href: '/admin/customers',
    matches: /^\/admin\/customer.*/,
    icon: <i className="uil-building" />
  },
  {
    title: 'tasks',
    href: '/admin/tasks',
    icon: <i className="uil-calender" />,
    matches: /^\/admin\/tasks.*/
  },
  {
    title: 'deductions',
    href: '/admin/deductions',
    matches: /^\/admin\/deduction.*/,
    icon: <i className="uil-bill" />
  },
  {
    title: 'mileages',
    href: '/admin/mileages',
    matches: /^\/mileages.*/,
    icon: <i className="uil-location-point" />,
    featureFlag: 'feature-mileages'
  },
  {
    title: 'mileageRequests',
    href: '/admin/mileage-requests',
    matches: /^\/mileage-requests.*/,
    icon: <i className="uil-user-square" />,
    featureFlag: 'feature-mileages'
  },
  {
    title: 'cooperationAgreements',
    href: '/admin/cooperations',
    matches: /^\/admin\/cooperations.*/,
    icon: <i className="mdi mdi-handshake-outline" />
  },
  {
    title: 'offer',
    href: '/admin/offers',
    matches: /^\/admin\/offers.*/,
    icon: <i className="mdi mdi-offer" />,
    featureFlag: 'feature-offer-sending'
  },
  {
    title: 'emailLogs',
    href: '/admin/email-logs',
    matches: /^\/admin\/email-logs.*/,
    icon: <i className="mdi mdi-email" />
  },
  {
    title: 'debug',
    href: '/admin/debug',
    matches: /^\/admin\/debug.*/,
    icon: <i className="uil uil-bug" />
  }
];

export const ownPages: PageItem[] = [
  {
    title: 'referrals',
    href: '/referrals',
    icon: <i className="uil-share-alt" />,
    anchorClassName: 'text-warning',
    badge: <AvailableCreditBadge />,
    featureFlag: 'feature-referral'
  },
  {
    title: 'helpCenter',
    href: 'https://intercom.help/factofly/da',
    matches: /^\/help\/center.*/,
    icon: <i className="mdi mdi-help-circle" />,
    target: '_blank'
  },
  {
    title: 'settings',
    href: '/settings',
    icon: <i className="uil-cog" />
  },
  {
    title: 'Feedback',
    href: '',
    component: <FeedbackMenuItem key="feedback" />
  },
  {
    title: 'logOut',
    href: LOGOUT_PAGE,
    icon: <i className="mdi mdi-logout-variant" />
  }
];
