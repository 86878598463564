import { useTranslation } from 'next-i18next';
import { FieldError, FieldErrors, useFormContext } from 'react-hook-form';

function FormValidationErrors() {
  const {
    formState: { errors }
  } = useFormContext();
  const { t } = useTranslation('common');

  if (Object.keys(errors).length === 0) {
    return null;
  }

  const renderChildErrors = (childErrors: FieldErrors, i: number) =>
    childErrors && (
      <ul className="text-danger list-unstyled">
        {Object.keys(childErrors)
          // .filter((key) => !!childErrors[key]?.message)
          .map((key) => (
            <li key={childErrors[key]?.message as string}>
              {t('messages.row')}&nbsp;{i + 1} - {childErrors[key]?.message as string}
            </li>
          ))}
      </ul>
    );

  return (
    <ul className="text-danger list-unstyled">
      {Object.keys(errors).map((key) => {
        const error = errors[key] as FieldError;
        const childErrors = errors[key] as FieldErrors;
        return (
          <li key={error.message}>
            {error.message}
            {childErrors &&
              Array.isArray(childErrors) &&
              childErrors.map((childError, i) => renderChildErrors(childError, i))}
          </li>
        );
      })}
    </ul>
  );
}

export default FormValidationErrors;
