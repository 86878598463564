// pages/_app.js
import { useEffect } from 'react';
import { useRouter } from 'next/router';

import posthog from 'posthog-js';
import { PostHogProvider as PostHogProviderInternal } from 'posthog-js/react';
import clientConfig from '~/clientConfig';
import apiConfig from '~/apiConfig';

// Check that PostHog is client-side (used to handle Next.js SSR)
if (typeof window !== 'undefined') {
  posthog.init(clientConfig.postHog.publicKey, {
    api_host: clientConfig.postHog.hostUrl,
    person_profiles: 'identified_only',
    // Enable debug mode in development
    loaded: (p) => {
      if (apiConfig.environment === 'development') p.debug();
    }
  });
}

export default function PostHogProvider({ children }: React.PropsWithChildren<{}>) {
  const router = useRouter();

  useEffect(() => {
    // Track page views
    const handleRouteChange = () => posthog?.capture('$pageview');
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return <PostHogProviderInternal client={posthog}>{children}</PostHogProviderInternal>;
}
