import toast from 'react-hot-toast';
import { useCallback } from 'react';

export default function useToast() {
  const toaster = useCallback(
    (message: string, { variant }: { variant: 'success' | 'error' | 'loading' | string }) => {
      switch (variant) {
        case 'success':
          toast.success(message);
          break;
        case 'error':
          toast.error(message);
          break;
        case 'loading':
          toast.loading(message);
          break;
        case 'info':
          toast(message, {
            style: {
              background: '#333'
            },
            icon: '🚀'
          });
          break;
        default:
          toast(message);
          break;
      }
    },
    []
  );

  const success = (message: string) => toaster(message, { variant: 'success' });
  const error = (message: string) => toaster(message, { variant: 'error' });
  const loading = (message: string) => toaster(message, { variant: 'loading' });

  return { toast: toaster, success, error, loading };
}
