import * as Sentry from '@sentry/nextjs';
export default class ErrorHandler {
    static configureScope(callback) {
        Sentry.configureScope(callback);
    }
    static getCurrentHub() {
        return Sentry.getCurrentHub();
    }
    static withScope(callback) {
        Sentry.withScope(callback);
    }
    static async flush(timeout) {
        await Sentry.flush(timeout);
    }
    static captureException(exception, captureContext) {
        Sentry.captureException(exception, captureContext);
    }
    static setContext(name, context) {
        Sentry.setContext(name, context);
    }
    static setUser(user) {
        Sentry.setUser(user);
    }
}
