import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useRouter } from 'next/router';
import useUser from '~/contexts/User/useUser';
import AccountSetupForm from './AccountSetupForm';

export default function AccountSetupModal() {
  const { accountSetupComplete, emailVerified, isImpersonating, isAdmin } = useUser();
  const {
    query: { show_setup: showSetup }
  } = useRouter();

  const showModal = (!accountSetupComplete || !!showSetup) && emailVerified && !isAdmin;
  const [isOpen, setIsOpen] = useState(showModal);
  const handleClose = () => setIsOpen(false);

  return (
    <div>
      <Modal
        show={isOpen}
        size="lg"
        centered
        className="pb-5"
        onHide={isImpersonating ? handleClose : () => {}}
      >
        {isImpersonating && <Modal.Header closeButton />}
        <Modal.Body className="p-0">
          {isOpen && <AccountSetupForm onCompleted={() => setIsOpen(false)} />}
        </Modal.Body>
      </Modal>
      <style jsx>{`
        embed {
          margin: auto;
        }
      `}</style>
    </div>
  );
}
