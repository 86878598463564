/* eslint-disable react/jsx-props-no-spreading */
import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';
import { FieldError } from './FieldError';
import RequiredSymbol from './RequiredSymbol';

type RadioBoxFieldProps = {
  name: string;
  label?: string;
  description?: string;
  required?: boolean;
  colClassName?: string;
  options: RadioBoxFieldOption[];
  readOnly?: boolean;
  showValidation?: boolean;
  showDescription?: boolean;
};

export type RadioBoxFieldOption = {
  value: string;
  label: string;
  description?: string;
};

function RadioBoxField({
  name,
  label = undefined,
  description = undefined,
  required = false,
  options,
  colClassName = 'col',
  readOnly = false,
  showValidation = true,
  showDescription = true
}: RadioBoxFieldProps) {
  const {
    register,
    watch,
    setValue,
    formState: { errors }
  } = useFormContext();

  const isValid = errors[name] === undefined;

  return (
    <>
      <div className={classNames('form-group ', { required, 'form-group-invalid': !isValid })}>
        {label && (
          <legend className="col-form-label pt-0">
            {label} {required && <RequiredSymbol />}
          </legend>
        )}
        <div className="row gx-1 gy-0">
          {options.map((item) => (
            <div className={colClassName} key={item.value}>
              <div
                className={classNames('form-check mb-1', {
                  checked: watch(name) === item.value,
                  'form-control is-invalid': !isValid
                })}
                role="button"
                tabIndex={0}
                onKeyDown={(e) => e.key === 'Enter' && setValue(name, item.value)}
                onClick={() => setValue(name, item.value)}
              >
                <div className="d-flex">
                  <div>
                    <input
                      className="form-check-input"
                      {...register(name)}
                      type="radio"
                      value={item.value}
                      disabled={readOnly}
                    />
                  </div>
                  <div>
                    {item.label}
                    <br />
                    {item.description && <span className="text-muted">{item.description}</span>}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        {showValidation && <FieldError error={errors[name]} />}
        {showDescription && <small className="form-text text-muted">{description}</small>}
      </div>
      <style jsx>{`
        legend {
          font-weight: 600;
        }
        label {
          font-weight: 400;
        }
        .form-check {
          border-radius: 3px;
          padding: 7px;
          cursor: pointer;
        }
        .form-check:not(.is-invalid) {
          border: 1px solid #dee2e6;
        }
        .form-check .form-check-input {
          float: none;
          margin: 3px 6px;
        }
        .form-check.checked {
          border: 1px solid #4f994f;
          background-color: #f2ffeb;
        }
        .form-check-input {
          margin-left: 20px;
        }
        .col-form-label {
          padding-bottom: 3px;
        }
        .text-muted {
          font-size: 0.8rem;
          color: #c3c3c3 !important;
        }
      `}</style>
    </>
  );
}

export default RadioBoxField;
