import useWindowDimensions from './useWindowDimensions';

export default function useResponsiveLayout() {
  const { width } = useWindowDimensions();

  return {
    isMobile: width < 576,
    isTablet: width >= 576 && width < 1024,
    isDesktop: width >= 1024
  };
}
