export const FEATURES = {
  REFERRAL: 'feature-referral',
  REWARD: 'feature-reward',
  EARLY_PAYMENT: 'feature-early-payment',
  PAYROLL_SYNC: 'feature-payroll-sync',
  AIIA_PAYMENTS: 'feature-aiia-payments',
  INVOICE_PAYMENT_LINK: 'feature-invoice-payment-link',
  CONTRACTS: 'feature-contracts',
  MILEAGES: 'feature-mileages',
  INVOICE_STATUS_MESSAGES: 'feature-invoice-status-message',
  OFFER_SENDING: 'feature-offer-sending',
  INTERNATIONAL_ACCOUNTS: 'feature-international-accounts',
  COOPERATION_AGREEMENTS: 'feature-cooperation-agreements',
  IDENTITY_VERIFICATION: 'feature-identity-verification',
  CREATE_MILEAGES: 'feature-create-mileages',
  USER_PAYROLL: 'feature-user-payroll'
};

export const FEATURE_VALUES = {
  REWARD_TYPE: 'reward-type',
  REWARD_VALUE_FIXED_DISCOUNT: 'reward-value-fixed-discount',
  REWARD_VALUE_MAX_DISCOUNT: 'reward-value-max-discount',
  REWARD_VALUE_PERCENTAGE_DISCOUNT: 'reward-value-percentage-discount',

  EARLY_PAYMENT_FEE_RATE: 'early-payment-fee-rate'
};
