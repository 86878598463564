import { FieldError as FieldErrorType, FieldErrorsImpl, Merge } from 'react-hook-form';

export type FieldErrorProps = {
  error: FieldErrorType | Merge<FieldErrorType, FieldErrorsImpl<any>> | undefined;
  insertErrorHtmlDangerously?: boolean;
};
export function FieldError({ error, insertErrorHtmlDangerously }: FieldErrorProps) {
  const errorMessage = error?.message as string;
  if (insertErrorHtmlDangerously && errorMessage) {
    return (
      <div className="invalid-feedback">
        {/* eslint-disable-next-line react/no-danger */}
        <span dangerouslySetInnerHTML={{ __html: errorMessage }} />
        <style jsx>
          {`
            .invalid-feedback {
              display: block;
            }
          `}
        </style>
      </div>
    );
  }
  return errorMessage ? (
    <div className="invalid-feedback">
      {errorMessage}
      <style jsx>
        {`
          .invalid-feedback {
            display: block;
          }
        `}
      </style>
    </div>
  ) : null;
}
