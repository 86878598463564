import { Toaster } from 'react-hot-toast';

export default function ToastBar() {
  return (
    <Toaster
      position="bottom-left"
      toastOptions={{
        style: {
          color: '#fff',
          fontSize: '14px',
          alignContent: 'left',
          padding: '16px',
          textAlign: 'left'
        },
        success: {
          iconTheme: {
            primary: '#fff',
            secondary: '#5cb85c'
          },
          style: {
            background: '#5cb85c'
          }
        },
        loading: {
          iconTheme: {
            primary: '#fff',
            secondary: '#333'
          },
          style: {
            background: '#333'
          }
        },
        error: {
          iconTheme: {
            primary: '#fff',
            secondary: '#d9534f'
          },
          style: {
            background: '#d9534f'
          }
        }
      }}
    />
  );
}
