import { useState, useCallback, useMemo, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import * as yup from 'yup';
import { useTranslation } from 'next-i18next';
import IdentityVerificationQuery from '../graphql/IdentityVerificationQuery.gql';
import { IdentityVerificationQuery as IdentityVerificationQueryType } from '../graphql/.generated/IdentityVerificationQuery';
import { Step } from '../Step';
import popupCenter from '~/utils/popupCenter';
import useInterval from '~/features/shared/hooks/useInterval';
import useForm from '~/features/forms/hooks/useForm';

const VERIFICATION_TIMEOUT = 10000;

type IdentityVerificationStepOptions = { onCompleted: () => void };

const useIdentityVerificationStep = (
  userId: bigint,
  { onCompleted }: IdentityVerificationStepOptions
): Step => {
  const { t } = useTranslation('users');

  const validationSchema = useMemo(
    () =>
      yup
        .object({
          identityVerified: yup.boolean().isTrue('You must verify your identity')
        })
        .required(),
    []
  );

  const { form } = useForm({
    validationSchema
  });

  const [verifying, setVerifying] = useState(false);
  const [window, setWindow] = useState<Window | null>(null);
  const [timer, setTimer] = useState(0);

  const { data, loading, error, refetch } = useQuery<IdentityVerificationQueryType>(
    IdentityVerificationQuery,
    {
      variables: { id: userId }
    }
  );

  const url = data?.me?.identityVerificationUrl;

  useEffect(() => {
    if (data?.me?.identityVerified !== form.watch('identityVerified')) {
      form.setValue('identityVerified', data?.me?.identityVerified);
    }
  }, [data, form]);

  const showVerificationWindow = useCallback(() => {
    if (url) {
      setWindow(popupCenter({ url, title: t('headers.verifyIdentity'), h: 700, w: 400 }));
    }
  }, [setWindow, t, url]);

  useInterval(() => {
    if (window && window.closed) {
      setVerifying(true);
      setWindow(null);
    }
    if (verifying) {
      if (data?.me?.identityVerified) {
        setVerifying(false);
        setTimer(0);
      }
      refetch();
      setTimer(timer + 500);
      if (timer > VERIFICATION_TIMEOUT) {
        setVerifying(false);
        setTimer(0);
      }
    }
  }, 500);

  return {
    name: 'identityVerification',
    data: {
      showVerificationWindow,
      verifying,
      identityVerified: data?.me?.identityVerified
    },
    loading,
    error,
    form,
    onSubmit: () => {
      onCompleted();
    }
  };
};

export default useIdentityVerificationStep;
