import { useEffect } from 'react';

export default function useInterval(func: Function, delay: number) {
  useEffect(() => {
    const interval = setInterval(() => {
      // periodically check to see if the user has been verified yet
      func();
    }, delay);

    return () => {
      clearInterval(interval);
    };
  }, [func, delay]);
}
