import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { MouseEventHandler } from 'react';

export type SubmitButtonProps = {
  title: string | JSX.Element;
  icon?: JSX.Element;
  saving?: boolean;
  className?: string;
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  variant?: 'primary' | 'success' | 'danger';
  testId?: string;
  savingTitle?: string;
};

function SubmitButton({
  icon,
  title,
  saving,
  className = undefined,
  onClick = undefined,
  disabled = false,
  variant,
  savingTitle,
  testId
}: SubmitButtonProps) {
  const { t: tCommon } = useTranslation('common');
  let buttonClassName = 'btn-primary';
  if (variant === 'success') {
    buttonClassName = 'bg-success';
  } else if (variant === 'danger') {
    buttonClassName = 'btn-danger';
  }

  const savingText = savingTitle || `${tCommon('buttons.saving')}...`;

  return (
    <>
      <button
        type={onClick ? 'button' : 'submit'}
        className={classNames('btn', buttonClassName, className)}
        onClick={onClick}
        disabled={saving || disabled}
        data-testid={testId}
      >
        {icon} {saving ? savingText : title}
      </button>
      <style jsx>
        {`
          .bg-success {
            color: #ffffff;
          }
          .bg-success:hover {
            background-color: #499349 !important;
          }
        `}
      </style>
    </>
  );
}

export default SubmitButton;
