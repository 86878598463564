import { useTranslation } from 'next-i18next';
import FormSection from '~/features/forms/components/FormSection';

type IdentityVerificationStepProps = {
  showVerificationWindow: () => void;
  verifying: boolean;
  identityVerified: boolean;
};

export default function IdentityVerificationStep({
  showVerificationWindow,
  verifying,
  identityVerified = true
}: IdentityVerificationStepProps) {
  const { t } = useTranslation('users');

  return (
    <FormSection>
      <div className="text-center">
        {identityVerified && (
          <h1 className="my-5">
            <i className="uil uil-check-circle text-success me-2" />
            <span>Identity verified</span>
          </h1>
        )}
        {!identityVerified && verifying && (
          <h1 className="my-5">
            <i className="uil uil-question-circle text-success me-2" />
            <span>Verifying...</span>
          </h1>
        )}
        {!identityVerified && !verifying && (
          <button type="button" className="btn p-3 px-4 btn-primary my-5" onClick={showVerificationWindow}>
            {t('buttons.verifyIdentity')}
          </button>
        )}
      </div>
    </FormSection>
  );
}
