import { createContext } from 'react';

export interface ForceParams {
  force?: boolean;
}

export interface SidebarContextState {
  closeMenu: (params?: ForceParams) => void;
  closeOnKeyPress: (event: any) => void;
  toggleMenu: (params?: ForceParams) => void;
  setFocusMode: (value: boolean) => void;
  onFocusBack: () => void;
  setFocusBackHandler: (handler: (() => void) | null) => void;
  onFocusClose: () => void;
  setFocusCloseHandler: (handler: (() => void) | null) => void;
  isFocusMode: boolean;
  isSidebarOpen: boolean;
  sidebarMiniMode: boolean;
}

export default createContext<SidebarContextState | null>(null);
