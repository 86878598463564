import { BrowserClient, Feedback, getClient } from '@sentry/react';
import { useTranslation } from 'next-i18next';

export default function FeedbackMenuItem() {
  const client = getClient<BrowserClient>();
  const feedback = client?.getIntegration(Feedback);
  const { t } = useTranslation('common');

  // Don't render custom feedback button if Feedback integration isn't installed
  if (!feedback) {
    return null;
  }

  return (
    <li className="side-nav-item">
      <button
        type="button"
        className="btn btn-link side-nav-link w-100 text-start"
        onClick={() => feedback.openDialog()}
      >
        <i className="uil-bug" />
        <span className="">{t('menu.reportABug')}</span>
      </button>
      <style jsx global>
        {`
          #sentry-feedback {
            --bottom: 1rem;
            --top: auto;
            --left: 1rem;
            --right: auto;
            --submit-background: #0142c2;
          }
        `}
      </style>
    </li>
  );
}
