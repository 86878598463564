import { useCallback, useState } from 'react';
import { logger } from '@factofly/logging';

export default function useLocalStorage<T>(key: string, initialValue: T) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === 'undefined') {
      return initialValue;
    }
    try {
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      if (item && item !== 'undefined') {
        return JSON.parse(item);
      }
      return initialValue;
    } catch (error) {
      // If error also return initialValue
      logger.error(error);
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = useCallback(
    (value: T | ((value: T) => void)) => {
      try {
        // Allow value to be a function so we have same API as useState
        const isFunction = value instanceof Function;
        const valueToStore = isFunction ? value(storedValue) : value;
        if (!isFunction && JSON.stringify(valueToStore) === JSON.stringify(storedValue)) return;

        // Save state
        setStoredValue(valueToStore);
        // Save to local storage
        if (typeof window !== 'undefined') {
          window.localStorage.setItem(key, JSON.stringify(valueToStore));
        }
      } catch (error) {
        // A more advanced implementation would handle the error case
        logger.error(error);
      }
    },
    [storedValue, key]
  );
  return [storedValue, setValue];
}
