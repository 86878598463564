// global ga
import { useTranslation } from 'next-i18next';
import { useCallback, useEffect, useState, useMemo } from 'react';
import classNames from 'classnames';
import Image from 'next/legacy/image';
import SubmitButton from '~/features/forms/components/SubmitButton';
import FormError from '~/features/forms/components/FormError';
import usePersonalDetailsForm from './steps/usePersonalDetailsForm';
import FadeIn from '~/features/shared/components/FadeIn';
import useUser from '~/contexts/User/useUser';
import FormWrapper from '~/features/forms/components/FormWrapper';
import FormValidationErrors from '~/features/forms/components/FormValidationErrors';
import PersonalDetailsForm from './steps/PersonalDetailsForm';
import useReferralSourceForm from './steps/useReferralSourceForm';
import ReferralSourceForm from './steps/ReferralSourceForm';
import useSituationForm from './steps/useSituationForm';
import SituationForm from './steps/SituationForm';
import useUpdateUser from '../../hooks/useUpdateInvoices';
import createAnalyticsPageView from '~/utils/createAnalyticsPageView';
import capitalizeFirstLetter from '~/utils/capitalizeFirstLetter';
import useResponsiveLayout from '~/features/shared/hooks/useResponsiveLayout';
import ButtonLink from '~/features/shared/components/ButtonLink';
import HelpLink from '~/features/shared/components/HelpLink';
import SetupComplete from './SetupComplete';
import IdentityVerificationStep from './steps/IdentityVerificationStep';
import useIdentityVerificationStep from './steps/useIdentityVerificationStep';
import { Step } from './Step';
import useFeatures from '~/features/shared/hooks/useFeatures';
import { FEATURES } from '~/constants/features';

type AccountSetupFormProps = {
  onCompleted: () => void;
};

enum StepNames {
  Situation = 'situation',
  Profile = 'profile',
  IdentityVerification = 'identityVerification',
  Referral = 'referral',
  Welcome = 'welcome'
}

function AccountSetupForm({ onCompleted }: AccountSetupFormProps): JSX.Element {
  const { t } = useTranslation('users');
  const { id: userId } = useUser();
  const { isMobile } = useResponsiveLayout();
  const { isEnabled } = useFeatures();

  const [completed, setCompleted] = useState(false);

  const [currentStep, setCurrentStep] = useState(0);

  const { updateUser } = useUpdateUser({ onCompleted });

  const prevStep = useCallback(() => {
    setCurrentStep((x) => x - 1);
  }, [setCurrentStep]);

  const nextStep = useCallback(() => {
    setCurrentStep((x) => x + 1);
  }, [setCurrentStep]);

  const completeAccountSetup = useCallback(() => {
    setCompleted(true);
  }, []);

  const welcomeAnimationCompleted = useCallback(() => {
    updateUser(userId, { accountSetupComplete: true });
  }, [userId, updateUser]);

  const situationForm = useSituationForm(userId, { onCompleted: nextStep });
  const profileForm = usePersonalDetailsForm(userId, { onCompleted: nextStep });
  const referralForm = useReferralSourceForm(userId, { onCompleted: nextStep });
  const idStep = useIdentityVerificationStep(userId, { onCompleted: nextStep });

  const steps: Step[] = useMemo(
    () => [
      { name: StepNames.Situation, ...situationForm },
      { name: StepNames.Profile, ...profileForm },
      ...(isEnabled(FEATURES.IDENTITY_VERIFICATION) ? [idStep] : []),
      { name: StepNames.Referral, ...referralForm },
      { name: StepNames.Welcome, onSubmit: nextStep }
    ],
    [situationForm, profileForm, referralForm, nextStep, idStep, isEnabled]
  );

  const stepName = steps[currentStep].name;

  useEffect(() => {
    createAnalyticsPageView(
      `Account Setup - ${capitalizeFirstLetter(stepName)}`,
      `/virtual/account-setup/${stepName}`
    );
  }, [stepName]);

  const progress = ((currentStep + 1) / (steps.length - 1)) * 100;

  const isFirstStep = currentStep === 0;
  const isLastStep = currentStep >= steps.length - 1;

  const step = steps[currentStep];

  if (step.loading) return <div className="loading" />;

  const lastStepTitle = t(isLastStep ? 'accountSetup.buttons.completeSetup' : 'accountSetup.buttons.next');

  return (
    <FadeIn>
      {step.name !== StepNames.Welcome && (
        <FormWrapper form={step.form} onSubmit={step.onSubmit}>
          <div className="card form-card m-0">
            <div className={classNames('card-body pb-2', { 'p-5': !isMobile, 'p-2': isMobile })}>
              <div className="text-center pb-3">
                <h2>{t(`accountSetup.steps.${step.name}.title`)}</h2>
                <p className="fs-4">{t(`accountSetup.steps.${step.name}.subtitle`)}</p>
              </div>
              {step.name === StepNames.Situation && <SituationForm isRequired={step.isRequired!} />}
              {step.name === StepNames.Profile && <PersonalDetailsForm isRequired={step.isRequired!} />}
              {step.name === StepNames.Referral && <ReferralSourceForm isRequired={step.isRequired!} />}
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              {step.name === StepNames.IdentityVerification && <IdentityVerificationStep {...step.data} />}
              <div className="my-3">
                <div>{t('accountSetup.stepOf', { count: currentStep + 1, max: steps.length - 1 })}</div>
                <div className="progress">
                  <div className="progress-bar" style={{ width: `${progress}%` }} />
                </div>
              </div>
            </div>
            <div className="card-footer p-2">
              {step.error && <FormError message={step.error.message} />}
              <FormValidationErrors />
              <div className="actions my-2">
                <button
                  type="button"
                  onClick={prevStep}
                  className={classNames('btn btn-secondary', { invisible: isFirstStep })}
                >
                  {t('accountSetup.buttons.previous')}
                </button>

                <SubmitButton className="float-end" title={lastStepTitle} saving={step.saving!} />
              </div>
            </div>
          </div>
        </FormWrapper>
      )}
      {step.name === StepNames.Welcome && (
        <div className="card continue-card m-0">
          {!completed && (
            <div className="card-body text-center">
              <div className="image-wrapper text-center p-3">
                <Image src="/images/fainting-happy.gif" width={300} height={170} />
              </div>
              <h2>{t('accountSetup.steps.welcome.title')}</h2>
              <p className="fs-4">{t(`accountSetup.steps.welcome.subtitle`)}</p>
              <div className="text-center mb-2 mt-3 links-card">
                <div>
                  <ButtonLink
                    className="py-3 px-5 mb-3"
                    title={t(`accountSetup.buttons.letsGetStarted`)}
                    href="/"
                    onClick={completeAccountSetup}
                  />
                </div>
                <div>
                  <HelpLink href="https://intercom.help/factofly/da">
                    {t(`accountSetup.buttons.getOnRightTrack`)}
                  </HelpLink>
                </div>
              </div>
            </div>
          )}
          {completed && (
            <div className="card-body text-center">
              <SetupComplete onWelcomeAnimationCompleted={welcomeAnimationCompleted} />
            </div>
          )}
        </div>
      )}
      <style jsx>{`
        .form-card,
        .loading {
          min-height: 600px;
        }
      `}</style>
    </FadeIn>
  );
}

export default AccountSetupForm;
