/**
 * Get cookie by name
 * @param cname The name of the cookie
 * @param cookies The cookies string
 * @returns The cookie value
 */
export default function getCookie(cname, cookies) {
    const name = `${cname}=`;
    const decodedCookie = decodeURIComponent(cookies);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i += 1) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
}
