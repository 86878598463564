import React from 'react';

import LogoImage from 'public/images/logo.svg';
import HaloweenLogoImageWhite from 'public/images/halloween-logo-white.svg';
import HaloweenLogoImageBlack from 'public/images/halloween-logo-black.svg';
import classNames from 'classnames';

type LogoProps = {
  width?: string;
  height?: string;
  variant?: 'light' | 'dark';
  className?: string;
};

function Logo({ height = '100%', width = '100%', variant = 'dark', className }: LogoProps): JSX.Element {
  const today = new Date();
  const isHalloween = today.getMonth() === 9 && today.getDate() >= 22;

  const HaloweenLogoImage = variant === 'light' ? HaloweenLogoImageWhite : HaloweenLogoImageBlack;

  return (
    <div className={classNames('logo logo-lg', className)}>
      {isHalloween && (
        <HaloweenLogoImage
          style={{ marginBottom: '-20px', marginTop: '-15px' }}
          height={parseInt(height.replace('px', ''), 10) + 40}
          width={width}
        />
      )}
      {!isHalloween && <LogoImage height={height} width={width} />}
      <style jsx>
        {`
          P .logo {
            display: inline-block;
            line-height: 120px;
            width: 260px;
          }
        `}
      </style>
    </div>
  );
}

export default Logo;
