import React, { Fragment, useCallback, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars-2';
import MenuItem from './MenuItem';
import { pages, admin, ownPages } from './MenuData';
import useUser from '~/contexts/User/useUser';
import useWindowDimensions from '~/features/shared/hooks/useWindowDimensions';
import useLocalStorage from '~/features/shared/hooks/useLocalStorage';
import { PageItem } from './PageItem';
import { LOGOUT_PAGE } from '~/constants/appRoutes';

type SidebarNavChildMenuProps = {
  items: any[];
};
function SidebarNavChildMenu({ items }: SidebarNavChildMenuProps) {
  return (
    <ul className="child-menu ps-2">
      {items.map((page: any) => (
        <MenuItem page={page} key={page.href} />
      ))}
    </ul>
  );
}

function useTranslatePages(p: PageItem[], t: any) {
  return useMemo(
    () =>
      p.map((page) => ({
        ...page,
        title: t(`menu.${page.title}`)
      })) /* .sort((a, b) => a.title.localeCompare(b.title)) */,
    [p, t]
  );
}

function SidebarNav() {
  const { emailVerified, isAdmin, id: loggedInUserId } = useUser();
  const { t } = useTranslation('common');
  const [showNavigation, setShowNavigation] = useLocalStorage(`${loggedInUserId}:show-navigation`, false);
  const { height } = useWindowDimensions();

  const toggleNavigation = useCallback(() => setShowNavigation((state: any) => !state), [setShowNavigation]);

  const menuData = useTranslatePages(pages, t);
  const adminMenuData = useTranslatePages(admin, t);
  const ownMenuData = useTranslatePages(ownPages, t);

  const canViewMenu = emailVerified;
  return (
    <Scrollbars style={{ width: 260, height: height - 70 }}>
      <ul className="side-nav">
        {canViewMenu && !isAdmin && (
          <>
            <li className="side-nav-title side-nav-item">
              {isAdmin ? (
                <div
                  className="pe-auto"
                  onClick={toggleNavigation}
                  onKeyDown={toggleNavigation}
                  role="button"
                  tabIndex={0}
                >
                  <i
                    className={classNames('uil float-end fs-5', {
                      'uil-angle-down': !showNavigation,
                      'uil-angle-up': showNavigation
                    })}
                  />
                  {t('menu.navigation')}{' '}
                </div>
              ) : (
                <>{t('menu.navigation')}</>
              )}
            </li>
            {(!isAdmin || showNavigation) && menuData.map((page) => <MenuItem page={page} key={page.href} />)}
          </>
        )}
        {isAdmin && (
          <>
            <li className="side-nav-title side-nav-item">{t('menu.admin')}</li>
            {adminMenuData.map((page) => (
              <Fragment key={page.href}>
                <MenuItem page={page} />
                {page.children && <SidebarNavChildMenu items={page.children} />}
              </Fragment>
            ))}
          </>
        )}
        <li className="side-nav-title side-nav-item">{t('menu.account')}</li>
        {canViewMenu &&
          ownMenuData.map((page) =>
            page.component ? page.component : <MenuItem page={page} key={page.href} />
          )}
        {!canViewMenu &&
          ownMenuData
            .filter(({ href }) => href === LOGOUT_PAGE)
            .map((page) => <MenuItem page={page} key={page.href} />)}
      </ul>
      <style jsx>{`
        .side-nav :global(.child-menu.bg-dark) {
          background-color: #365ba5 !important;
        }
        .side-nav :global(.side-nav-link.active) {
          background-color: hsl(220 99% 33% / 1);
        }
      `}</style>
    </Scrollbars>
  );
}

export default SidebarNav;
